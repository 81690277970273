import React from 'react';
import classNames from 'classnames';

import { ReactComponent as LineSvg } from './line.svg';
import styles from './ArticleCard.module.scss';
import { ArticleCardSquare } from './ArticleCardSquare';

const ArticleCard = ({ className, title, slug, tags, svgIndex = 0, ...props }) => {
  return (
    <div className={classNames(styles.card, className)}>
      <ArticleCardSquare title={title} to={`/${slug}`} svgIndex={svgIndex} {...props} />
      <div className={styles.tags}>
        {tags.map((tag, index) => (
          <div key={`tag-${index}`} className={styles.tag}>
            <span>{tag.name}</span>
            <LineSvg preserveAspectRatio="none" className={styles.tagSvg} />
          </div>
        ))}
      </div>
    </div>
  );
};

export { ArticleCard };
