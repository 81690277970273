import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as BoxSvg } from '../../../../images/svg/box.svg';

import './Tag.scss';

const Tag = ({ children, active, className, ...props }) => {
  return (
    <button className={classNames('tag-button', { active }, className)} {...props}>
      <BoxSvg preserveAspectRatio="none" className="tag-button-svg" />
      <span className="tag-button-text">{children}</span>
    </button>
  );
};

Tag.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

export { Tag };
