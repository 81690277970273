import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { ArticleCard } from '../../ArticleCard';
import { Tag } from '../Tag';
import { useAppContext } from '../../../context/AppContext';
import { getPostCategory, getTagsFromPost } from '../../../../utils/data';
import { Title } from '../../Title';
import { Button } from '../../Button';
import { Icon } from '../../Icon/Icon';
import styles from './TagsBlock.module.scss';

const BENS_TAGS = [
  'Ben',
  'Thomas',
  'Robyn',
  'Castro',
  'Aysen',
  'Rajah',
  'Is',
  'Isn’t',
  'Abstract',
  'Nature',
  'Words',
  'Transport',
  'Shapes',
  'People',
];

const TagsBlock = ({ tags, posts }) => {
  const { currentTag, updateCurrentTag } = useAppContext();
  const [numArticles, setNumArticles] = useState(0);
  const resultsRef = useRef(null);

  // This is a shit way of doing this, but i cba and want to get it done
  const filteredTags = useMemo(() => {
    return tags.filter(tag => !BENS_TAGS.includes(tag.node.name));
  }, [tags]);

  // Make sure no tag filters applied
  useEffect(() => {
    updateCurrentTag('none');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredPosts = useMemo(() => {
    const filteredByTag = [];

    if (currentTag === 'none') {
      return [];
    } else {
      for (let i = 0; i < posts.length; i++) {
        const postTags = getTagsFromPost(posts[i]);

        if (postTags.includes(currentTag)) {
          filteredByTag.push(posts[i]);
        }
      }
      setNumArticles(filteredByTag.length);
      return filteredByTag;
    }
  }, [currentTag, posts]);

  const onFilterClick = useCallback(tagName => {
    updateCurrentTag(tagName);

    if (resultsRef.current) {
      window.scrollBy({
        top:
          resultsRef.current.getBoundingClientRect().top -
          document.querySelector('header').getBoundingClientRect().height,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  const handleResetTagFilters = e => {
    // Set the current tag state
    updateCurrentTag('none');
  };

  return (
    <>
      {/* Tags */}
      <div className={styles.tagsContainer}>
        {filteredTags.map((tag, index) => (
          <Tag
            className={styles.tag}
            active={currentTag === tag.node.name}
            key={`tag-item-${index}`}
            onClick={() => onFilterClick(tag.node.name)}
          >
            {tag.node.name}
          </Tag>
        ))}
      </div>
      <div className={styles.prompt}>
        Scroll for more <Icon className={styles.promptIcon} size="free" name="arrow-drawn" />
      </div>
      {/* Posts */}
      <div ref={resultsRef} className={styles.tagsResultsHeader}>
        {currentTag !== 'none' && (
          <Title tagName="h4" size="small">
            There {numArticles !== 1 ? `are ${numArticles} articles in ` : 'is 1 article in '}'{currentTag}'
          </Title>
        )}
        {currentTag !== 'none' && (
          <Button icon="x" onClick={() => handleResetTagFilters()} className={styles.clear}>
            Clear
          </Button>
        )}
      </div>
      <div className={styles.tagsResults}>
        {filteredPosts.map((post, index) => {
          const { slug: categorySlug, comingSoon } = getPostCategory(post.node.categories);
          return (
            <ArticleCard
              title={post.node.title}
              className={categorySlug}
              slug={post.node.slug}
              tags={post.node.tags.nodes}
              key={`article-card-${index}`}
              svgIndex={index}
              colorHover
              comingSoon={comingSoon}
            />
          );
        })}
      </div>
    </>
  );
};

export { TagsBlock };
