import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { TextSvg } from '../TextSvg';
import ComingSoonSvg from '../../../images/svg/coming-soon.svg';

import styles from './ArticleCard.module.scss';

const ArticleCardSquare = ({ className, svgIndex = 0, title, comingSoon, to, image, video, audio, ...props }) => {
  return (
    <TextSvg
      className={classNames(styles.square, className)}
      innerClassName={styles.squareText}
      tagEl={comingSoon ? 'span' : Link}
      svg={`Square00${(svgIndex % 6) + 1}`}
      svgClipName={`square00${(svgIndex % 6) + 1}Clip`}
      image={comingSoon ? ComingSoonSvg : image}
      video={!comingSoon && video}
      audio={!comingSoon && audio}
      containImage={comingSoon}
      to={comingSoon ? undefined : to}
      {...props}
    >
      <div className={styles.squareTextBlock}>{title}</div>
    </TextSvg>
  );
};

export { ArticleCardSquare };
