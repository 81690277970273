import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Wrapper } from '../components/layout/Wrapper';

import { HubAudioPlayer } from '../components/common/HubAudioPlayer';
import PostsListing from '../components/pagelayout/category/PostsListing';
import { TagsSectionFooter } from '../components/layout/TagsSectionFooter';
import { Breadcrumb } from '../components/common/Breadcrumb';
import { getCategoryColor } from '../components/context/AppContext';
import { HandwrittenTitle } from '../components/common/HandwrittenTitle';
import SEO from '../components/common/seo';

import { ReactComponent as Circle001 } from '../components/common/TextSvg/assets/001.svg';
import { ReactComponent as Circle002 } from '../components/common/TextSvg/assets/002.svg';
import { ReactComponent as Circle003 } from '../components/common/TextSvg/assets/003.svg';
import { ReactComponent as Circle004 } from '../components/common/TextSvg/assets/004.svg';
import { getOrderedCategoryPosts } from '../utils/data';

import styles from './Category.module.scss';

const CIRCLES = [Circle001, Circle002, Circle003, Circle004];

export const query = graphql`
  query($slug: String!) {
    category: wpCategory(slug: { eq: $slug }) {
      name
      categoryExtraFields {
        order
        fullDescription
        fullDescriptionAudioAlternative {
          localFile {
            publicURL
          }
        }
      }
      posts {
        nodes {
          title
          postOrder {
            order
          }
          slug
          articleDescription {
            shortDescription
          }
          audioAlternative {
            shortDescriptionAudioAlternative {
              localFile {
                publicURL
              }
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          mp4Rollover {
            mp4Rollover {
              altText
              localFile {
                publicURL
              }
            }
            rolloverAudio {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

const CategoryTemplate = ({ data }) => {
  const category = data.category;
  const audioAlternative = category.categoryExtraFields?.fullDescriptionAudioAlternative?.localFile?.publicURL;
  const categoryPosts = category.posts.nodes;

  const orderedPosts = getOrderedCategoryPosts(categoryPosts);

  const SvgEl = CIRCLES[(category.categoryExtraFields.order - 1) % 4];

  return (
    <div>
      <SEO title={category.name} />
      <Helmet
        bodyAttributes={{
          class: getCategoryColor(category.name),
        }}
      />

      <Wrapper border wrapped>
        <Breadcrumb
          items={[
            {
              to: '/',
              text: 'Home',
            },
            category.name,
          ]}
        />
      </Wrapper>
      <Wrapper padded wrapped border>
        {/* Title */}
        <div className={styles.titleWrapper}>
          <SvgEl className={styles.titleGraphic} />
          <HandwrittenTitle tagEl="h1" title={category.name} className={styles.title} responsive />
        </div>
        {/* Description */}
        <div className={styles.info}>
          {category.categoryExtraFields.fullDescription}
          {audioAlternative && (
            <div className={styles.infoAudio}>
              <HubAudioPlayer className={styles.listen} src={audioAlternative} label="Listen" />
            </div>
          )}
        </div>

        {/* Articles */}
        {orderedPosts.length > 0 && <PostsListing posts={orderedPosts} />}
      </Wrapper>

      <Wrapper padded wrapped>
        <TagsSectionFooter />
      </Wrapper>
    </div>
  );
};

export default CategoryTemplate;
