import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Icon } from '../../common/Icon/Icon';
import { TagsBlock } from '../../common/tags/TagsBlock';
import { Title } from '../../common/Title';

const TagsSectionFooter = ({ tags, posts }) => {
  const data = useStaticQuery(graphql`
    query TagsFooterQuery {
      allTags: allWpTag {
        edges {
          node {
            name
            slug
          }
        }
      }

      allPosts: allWpPost {
        edges {
          node {
            title
            slug
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
            mp4Rollover {
              mp4Rollover {
                altText
                localFile {
                  publicURL
                }
              }
              rolloverAudio {
                localFile {
                  publicURL
                }
              }
            }
            categories {
              nodes {
                slug
                categoryExtraFields {
                  comingSoon
                }
              }
            }
            tags {
              nodes {
                slug
                name
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <Title size="normal" tagName="h2">
        Or click <Icon flip name="click" size="large" /> on these words to find what you're looking for
      </Title>
      <TagsBlock tags={data.allTags.edges} posts={data.allPosts.edges} />
    </div>
  );
};

export { TagsSectionFooter };
