import React, { useMemo } from 'react';
import { HubAudioPlayer } from '../../../common/HubAudioPlayer';
import { ArticleCardSquare } from '../../../common/ArticleCard/ArticleCardSquare';
import { getFeaturedImageSrc } from '../../../../utils/data';
import { useAppContext } from '../../../context/AppContext';
import { MuteButton } from '../../../common/MuteButton';
import styles from './PostsListing.module.scss';

const PostsListing = ({ posts }) => {
  const { muteInteractions } = useAppContext();

  const sortedPosts = useMemo(() => {
    return posts.map((post, index) => {
      return {
        title: post.title,
        slug: post.slug,
        description: post.articleDescription?.shortDescription,
        audioFile: post.mp4Rollover?.rolloverAudio?.localFile?.publicURL,
        audioAlternative: post.audioAlternative?.shortDescriptionAudioAlternative?.localFile?.publicURL,
        image: getFeaturedImageSrc(post.featuredImage),
        video: post.mp4Rollover?.mp4Rollover?.localFile?.publicURL,
      };
    });
  }, [posts]);

  return (
    <div className={styles.list}>
      <MuteButton size="free" className={styles.muter} />

      {sortedPosts.map((post, index) => (
        <div key={`category-post-${index}`} className={styles.listingRow}>
          <ArticleCardSquare
            to={`/${post.slug}`}
            className={styles.square}
            // image={post.image}
            video={post.video}
            audio={!muteInteractions && post.audioFile}
            title={post.title}
            svgIndex={index}
          />
          <div className={styles.listingRowDescription}>
            <p>{post.description}</p>
            {post.audioAlternative && <HubAudioPlayer src={post.audioAlternative} label="Listen" />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostsListing;
